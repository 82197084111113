
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexi_45QcmcNxovV8U_f46XA_45DAgOK0U4R2RydFoH4tfxGEkMeta } from "/home/library/www/frontend/sites/creced/pages/index.vue?macro=true";
import { default as _91id_93pakQyXIHhaT0_45jvWTh8w7RItoEW6O6hLGGb8E__5sEYMeta } from "/home/library/www/frontend/pages/form/[id].vue?macro=true";
import { default as indexLbMYHxMyx1OQOzYuLmevfNyLUN1u0rhVu7jZrn3o29kMeta } from "/home/library/www/frontend/pages/bible/index.vue?macro=true";
import { default as searchRPeCWOWopjrwuOiaCOXUFuly9VZsdtOEzHwvzKMBtB0Meta } from "/home/library/www/frontend/pages/books/search.vue?macro=true";
import { default as indexKH2U3JbJ9V1HJS7yGdeIIU5U3VHWU00zdxpvOi1VPlEMeta } from "/home/library/www/frontend/pages/yearlist/index.vue?macro=true";
import { default as indexIWBfaMx6xt6f_S4qCDBfaNEAe4qfqCz5hwtKU159oIAMeta } from "/home/library/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as _91_91letter_93_93wEz_454zgsXYUixsZS71GubejY0qicRuzliwPuEnI8ltkMeta } from "/home/library/www/frontend/pages/tags/[[letter]].vue?macro=true";
import { default as index2EM631DyGdfJu89OmiOOW5nfOGLSJwimt60DLEFZ1CMMeta } from "/home/library/www/frontend/pages/user/[id]/index.vue?macro=true";
import { default as indexAOn8sMMYWSQOIa8x8STePmVwerzUqyWP8H6AeFeCUdsMeta } from "/home/library/www/frontend/sites/creced/pages/biblioteca/index.vue?macro=true";
import { default as indexD8mAxLbNcRM5_NmUWDtwj9lK_458g3rXTSyfqeq5ys2FcMeta } from "/home/library/www/frontend/pages/user/login/index.vue?macro=true";
import { default as booki5ivwJb6nesoFc8uf0klha_Ee2cNFA9Ul1mO6ZEGhagMeta } from "/home/library/www/frontend/pages/bible/custom/book.vue?macro=true";
import { default as biblejzQRpNEv6y96Ib2JMWIFLFsS_me9ibwwoYRkEmgrymEMeta } from "/home/library/www/frontend/pages/bible/custom/bible.vue?macro=true";
import { default as indexVpt9HjeeSrGdlXYTvMnm4XM7tPNWAqnDcYra0lvmdCYMeta } from "/home/library/www/frontend/pages/bible/custom/index.vue?macro=true";
import { default as indexUiYktpqCCFFRiRMbH2feK898j9fzdeLaibhWkCtfklcMeta } from "/home/library/www/frontend/pages/bible/[bible]/index.vue?macro=true";
import { default as indexmn2odWkdatq2PUYTP7FXbwUsfHINAQxciMQSnM3_Xs4Meta } from "/home/library/www/frontend/pages/user/password/index.vue?macro=true";
import { default as chapterhASaxPVHE6KcOoCCAG0qFfvpSrXrjJA0KP3pD76SSc4Meta } from "/home/library/www/frontend/pages/bible/custom/chapter.vue?macro=true";
import { default as _91id_93Jm_45lhmTugtl7ZSL5PwvhqKgr119wqAXgZaykZDmMAyYMeta } from "/home/library/www/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexkof6usKgWcEolM30wP9ZBNL4G9svk3qAELJVSoB82DcMeta } from "/home/library/www/frontend/pages/search/[[books]]/index.vue?macro=true";
import { default as indexOlLS5T_45_alAjgi55ruyu6C6axhyc8cNR6z_45cmObYbGsMeta } from "/home/library/www/frontend/pages/bible/[bible]/[book]/index.vue?macro=true";
import { default as indexfOr1s047QoHB5z6p8EYcCFwtbFLtut2ycjDv_Y9Rr54Meta } from "/home/library/www/frontend/pages/bible/[bible]/[book]/[chapter]/index.vue?macro=true";
import { default as _91_91login_93_9356Ff8Yvcb7_45cGCix39IWeTtMAXdD3rQYFYUVE_NTwWgMeta } from "/home/library/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue?macro=true";
export default [
  {
    name: "home",
    path: "/",
    meta: indexi_45QcmcNxovV8U_f46XA_45DAgOK0U4R2RydFoH4tfxGEkMeta || {},
    component: () => import("/home/library/www/frontend/sites/creced/pages/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_93pakQyXIHhaT0_45jvWTh8w7RItoEW6O6hLGGb8E__5sEYMeta || {},
    component: () => import("/home/library/www/frontend/pages/form/[id].vue")
  },
  {
    name: "library-bible-parent",
    path: "/bible",
    meta: indexLbMYHxMyx1OQOzYuLmevfNyLUN1u0rhVu7jZrn3o29kMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/index.vue")
  },
  {
    name: "search-books",
    path: "/books/search",
    meta: searchRPeCWOWopjrwuOiaCOXUFuly9VZsdtOEzHwvzKMBtB0Meta || {},
    component: () => import("/home/library/www/frontend/pages/books/search.vue")
  },
  {
    name: "yearlist",
    path: "/yearlist",
    meta: indexKH2U3JbJ9V1HJS7yGdeIIU5U3VHWU00zdxpvOi1VPlEMeta || {},
    component: () => import("/home/library/www/frontend/pages/yearlist/index.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexIWBfaMx6xt6f_S4qCDBfaNEAe4qfqCz5hwtKU159oIAMeta || {},
    component: () => import("/home/library/www/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "tags",
    path: "/tags/:letter?",
    meta: _91_91letter_93_93wEz_454zgsXYUixsZS71GubejY0qicRuzliwPuEnI8ltkMeta || {},
    component: () => import("/home/library/www/frontend/pages/tags/[[letter]].vue")
  },
  {
    name: "user-overview",
    path: "/user/:id()",
    meta: index2EM631DyGdfJu89OmiOOW5nfOGLSJwimt60DLEFZ1CMMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/[id]/index.vue")
  },
  {
    name: "biblioteca",
    path: "/biblioteca",
    meta: indexAOn8sMMYWSQOIa8x8STePmVwerzUqyWP8H6AeFeCUdsMeta || {},
    component: () => import("/home/library/www/frontend/sites/creced/pages/biblioteca/index.vue")
  },
  {
    name: "login",
    path: "/user/login",
    meta: indexD8mAxLbNcRM5_NmUWDtwj9lK_458g3rXTSyfqeq5ys2FcMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/login/index.vue")
  },
  {
    name: "bible-custom-book",
    path: "/bible/custom/book",
    meta: booki5ivwJb6nesoFc8uf0klha_Ee2cNFA9Ul1mO6ZEGhagMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/book.vue")
  },
  {
    name: "bible-custom-bible",
    path: "/bible/custom/bible",
    meta: biblejzQRpNEv6y96Ib2JMWIFLFsS_me9ibwwoYRkEmgrymEMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/bible.vue")
  },
  {
    name: "bible-custom",
    path: "/bible/custom",
    meta: indexVpt9HjeeSrGdlXYTvMnm4XM7tPNWAqnDcYra0lvmdCYMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/index.vue")
  },
  {
    name: "library-bible",
    path: "/bible/:bible()",
    meta: indexUiYktpqCCFFRiRMbH2feK898j9fzdeLaibhWkCtfklcMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/index.vue")
  },
  {
    name: "user-password",
    path: "/user/password",
    meta: indexmn2odWkdatq2PUYTP7FXbwUsfHINAQxciMQSnM3_Xs4Meta || {},
    component: () => import("/home/library/www/frontend/pages/user/password/index.vue")
  },
  {
    name: "bible-custom-chapter",
    path: "/bible/custom/chapter",
    meta: chapterhASaxPVHE6KcOoCCAG0qFfvpSrXrjJA0KP3pD76SSc4Meta || {},
    component: () => import("/home/library/www/frontend/pages/bible/custom/chapter.vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93Jm_45lhmTugtl7ZSL5PwvhqKgr119wqAXgZaykZDmMAyYMeta || {},
    component: () => import("/home/library/www/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "search",
    path: "/search/:books?",
    meta: indexkof6usKgWcEolM30wP9ZBNL4G9svk3qAELJVSoB82DcMeta || {},
    component: () => import("/home/library/www/frontend/pages/search/[[books]]/index.vue")
  },
  {
    name: "library-bible-book",
    path: "/bible/:bible()/:book()",
    meta: indexOlLS5T_45_alAjgi55ruyu6C6axhyc8cNR6z_45cmObYbGsMeta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/[book]/index.vue")
  },
  {
    name: "library-bible-book-chapter",
    path: "/bible/:bible()/:book()/:chapter()",
    meta: indexfOr1s047QoHB5z6p8EYcCFwtbFLtut2ycjDv_Y9Rr54Meta || {},
    component: () => import("/home/library/www/frontend/pages/bible/[bible]/[book]/[chapter]/index.vue")
  },
  {
    name: "passwordResetLogin",
    path: "/user/reset/:id()/:timestamp()/:hash()/:login?",
    meta: _91_91login_93_9356Ff8Yvcb7_45cGCix39IWeTtMAXdD3rQYFYUVE_NTwWgMeta || {},
    component: () => import("/home/library/www/frontend/pages/user/reset/[id]/[timestamp]/[hash]/[[login]].vue")
  }
]